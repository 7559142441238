<!--
 * @Descripttion:
 * @version:
 * @Author: 顾俊杰
 * @Date: 2020-12-28 11:51:58
 * @LastEditors: 顾俊杰
 * @LastEditTime: 2020-12-28 11:52:09
-->
<template>
  <div class="bxbox">
    <p class="bxtitle">{{ title}}</p>
    <div class="libcontent">
      <div class="content"></div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      title: '',
      desc: ''
    }
  },
  created () {
    console.log(this.$route.query)
    this.title = this.$route.query.setmark
    this.desc = this.$route.query.setcontent
  },
  mounted () {
    this.$nextTick(() => {
      window.document.title = this.title
      $('.content').load(this.desc)
    })
  }
}
</script>
<style scoped>
.bxbox {
  padding: 0.2rem;
}
.bxtitle {
  font-size: 0.45rem;
  font-weight: bold;
  padding: 0.4rem 0.5rem;
}
</style>
